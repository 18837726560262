.splitSectionRightImage{
    padding-top: 64px;
    padding-bottom: 64px;
}

@media only screen and (max-width: 768px) {
    .splitSectionRightImage{
        display: none !important;
        min-height: 0 !important;
    }
}
