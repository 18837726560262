body,
input,
a,
button {
  font-family: Mercury, Arial, Sans-Serif, serif;
}

body {
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex: 1;
}

a {
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "Mercury";
  src: url("./fonts/mercury/Mercury Regular.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
